.bg {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.circle1 {
  width: calc(768px - 250px);
  height: calc(768px - 250px);
  background-color: #695a90;
  position: absolute;
  left: 33%;
  top: 10%;
  transform: translateX(-50%);
  border-radius: 50%;
  filter: blur(170px);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(170px);
    -webkit-backdrop-filter: blur(170px);
  }
}
.circle2 {
  width: calc(768px - 100px);
  height: calc(768px - 100px);
  background-color: #5a8690;
  position: absolute;
  left: 70%;
  top: 14%;
  transform: translateX(-50%);
  border-radius: 50%;
  filter: blur(140px);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(140px);
    -webkit-backdrop-filter: blur(140px);
  }
}

.root {
  position: relative;
  background: none !important;
  overflow-x: hidden;
  overflow-x: clip;
}

.fullscreen {
  z-index: 10;
}
.minHeight {
  height: 1px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .circle1 {
    width: 50%;
    height: 50%;
    left: 5%;
    background-attachment: fixed;
  }
  .circle2 {
    width: 50%;
    height: 50%;
    left: 50%;
    background-attachment: fixed;
  }
}
