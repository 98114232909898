.root {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(34, 36, 42, 0.25);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 10;
}

.menuButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.burgerMenu {
  width: 20px;
  height: 2px;
  background-color: #fff;
}

.burgerMenu::after,
.burgerMenu::before {
  content: '';
  width: 20px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.burgerMenu::before {
  top: 8px;
}
.burgerMenu::after {
  bottom: 8px;
}

/* Drawer */

.drawer :global .ant-drawer-body {
  padding: 0;
  padding-top: 48px;
}

.menu {
  border-right-color: transparent;
  font-size: 16px;
}

.menu :global .ant-menu-item-icon {
  font-size: 20px;
}

.menu :global .ant-menu-item {
  height: 48px;
}

.menu,
.menu li,
.menu ul {
  background: none !important;
}

.menuItemIcon {
  svg {
    width: 24px !important;
  }
}

.menuStandalone {
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 16px;
  margin-bottom: 8px;
}

.menuStandalone svg {
  font-size: 24px;
}

.uppercase::first-letter {
  display: block;
  text-transform: uppercase;
}

.authWrap {
  width: 100%;
  padding: 0px 24px;
  margin-bottom: 18px;
}

.email {
  max-width: 340px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.wrapIcon {
  margin-right: 15px;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  cursor: pointer;
  .quantity {
    background-color: rgb(246, 70, 93);
    border-radius: 50%;
    font-size: 10px;
    color: #ffffff;
    position: absolute;
    left: 8px;
    top: -10px;
    width: 22px;
    height: 22px;
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nonNotification {
  margin-right: 3px;
}
