.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper > *:not(:last-child) {
  margin-bottom: 16px;
}
.wrapper > *:not(:first-child) {
  margin-top: 16px;
}

.title {
  font-size: 58px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0;
  line-height: 1;
  text-align: center;
  font-weight: 900;
}

.title_2 {
  font-size: 58px;
  color: #fff;
  margin-bottom: 0;
  line-height: 1;
  text-align: center;
  font-weight: 900;
}

.desc {
  font-size: 46px;
  color: var(--orange-main);
  margin: 0;
  text-align: center;
  font-weight: 900;
}

.logo {
  display: block;
  max-width: 360px;
}

.bg {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.circle1 {
  width: calc(768px - 250px);
  height: calc(768px - 250px);
  background-color: #695a90;
  position: absolute;
  left: 33%;
  top: 10%;
  transform: translateX(-50%);
  border-radius: 50%;
  filter: blur(170px);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(170px);
    -webkit-backdrop-filter: blur(170px);
  }
}
.circle2 {
  width: calc(768px - 100px);
  height: calc(768px - 100px);
  background-color: #5a8690;
  position: absolute;
  left: 70%;
  top: 14%;
  transform: translateX(-50%);
  border-radius: 50%;
  filter: blur(140px);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(140px);
    -webkit-backdrop-filter: blur(140px);
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding-top: 100px;
  }
  .title {
    font-size: 32px;
  }
  .desc {
    font-size: 22px;
  }
}
