.root {
  border-radius: 999px !important;
  box-shadow: 0px 0px 10px #00000026 !important;
  padding: 12px 36px !important;
  height: 40px !important;
  line-height: 1 !important;
}

.default {
  border-width: 2px !important;
  color: var(--orange-main) !important;
}

.primary {
  border: none !important;
  background: var(--orange-main) !important;
  color: var(--orange-dark) !important;
  &:hover {
    background: #ffffff !important;
    color: var(--orange-main) !important;
  }
}

.text {
  border: none !important;
  background: none !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.secondary {
  border: none !important;
  background: var(--accent-turqoise) !important;
  color: #ffffff !important;
  &:hover {
    background: #ffffff !important;
    color: var(--accent-turqoise) !important;
  }
}

.info {
  border: none !important;
  background: var(--accent-blue) !important;
  color: #ffffff !important;
  &:hover {
    background: #ffffff !important;
    color: var(--accent-blue) !important;
  }
}

.turqoise {
  border: none !important;
  background: #ffffff !important;
  color: var(--accent-turqoise) !important;
  &:hover {
    background: var(--accent-turqoise) !important;
    color: #ffffff !important;
  }
}

.blue {
  border: none !important;
  background: #ffffff !important;
  color: var(--accent-blue) !important;
  &:hover {
    background: var(--accent-blue) !important;
    color: #ffffff !important;
  }
}

.accent-turqoise {
  background: var(--accent-turqoise) !important;
  border: none;
  color: #fff !important;
}

.accent-blue {
  border: 1px solid var(--accent-blue) !important;
  color: #fff !important;
}

.success {
  background: var(--success) !important;
  border: none;
  color: #09625d !important;

  &:hover {
    color: #fff !important;
  }
}

.disabled {
  background: #797979 !important;
  border: none;
  color: var(--tint-2) !important;
  &:hover {
    color: #fff !important;
  }
}

.buy {
  border: none !important;
  background: var(--positive) !important;
  color: var(--dark-background) !important;
}

.sell {
  border: none !important;
  background: var(--negative) !important;
  color: var(--dark-background) !important;
}

.error {
  border: none !important;
  background: var(--error) !important;
  color: #fff !important;
  &:hover {
    color: var(--error) !important;
    background: #fff !important;
  }
}

[data-theme='light'] .buy,
.sell,
.error {
  color: #fff !important;
}

.large {
  height: 58px !important;
}

.large span {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.small {
  height: 20px !important;
  padding: 2px 12px 3px 12px !important;
  border-width: 1px !important;
}

.small span {
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 19px !important;
}

.default.small span {
  line-height: 14px !important;
}

.root[ant-click-animating-without-extra-node='true']::after {
  display: none;
}
