.avatar {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.divider {
  text-align: center;
  font-size: 20px !important;
  font-weight: bold !important;
  color: #ffffff !important;
  line-height: 20px;
}

.body {
  margin-top: 26px;
  margin-bottom: 30px;
}

.wrapButton {
  text-align: center;
  margin-bottom: -40px;
  & > button:nth-child(1) {
    color: #fff !important;
    background: var(--accent-blue) !important;
  }
  & > button:nth-child(1):hover {
    background: #fff !important;
    color: var(--accent-blue) !important;
  }
}
.header {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.time {
  color: rgb(132, 142, 156);
  margin-bottom: 16px;
}
.content {
  color: rgb(183, 189, 198);
}
