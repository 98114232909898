.iconSuccess {
  color: var(--success);
}

.iconError {
  color: var(--error);
}

.iconWarning {
  color: var(--orange-main);
}
