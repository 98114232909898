.root {
  text-align: center;
  font-size: 9px;
  margin-top: -2px;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: transparent;
}

.primary {
  border: 2px solid var(--orange-main);
}
.secondary {
  border: 2px solid #ffffff26;
}

.noBorder {
  border: none !important;
}

[data-theme='light'] .secondary {
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #ffffff;
}
