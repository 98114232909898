:root {
  /* Colors: */
  --pink-indicator: #d40159;
  --green-indicator: #00fe9a;
  --orange-dark: #8b4500;
  --orange-main: #ffba00;
  --turqoise-dark: #09625d;
  --accent-turqoise: #0d9d95;
  --accent-blue: #4192ff;
  --blue-contrast: #001d57;
  --black-main: #19222b;
  --dark-background: #22242a;
  --dark-background-light: #40454f;
  --koyu-mor: #0a1752;
  --gri-lacviert: #14224b;
  --koyu-lacivert: #656d81;
  --lacivert: #001a63;
  --neon-mavi: #00f5ff;
  --tint-1: #797979;
  --tint-2: #9aa3a7;
  --tint-3: #bfc1c3;
  --tint-4: #cdd1d3;
  --success: #12cea3;
  --error: #ff557c;
  --negative: #ff006a;
  --positive: #00fe9a;

  --bg-color: #22242a;
  --text-color: #fff;
  --text-secondary: #fff;
  --scroll-track: #40454f;
  --scroll-thumb: rgba(146, 146, 146, 0.623);

  /* Sizes: */
  --nav-bar-height: 75px;
  --surface-padding: 23px 24px;

  /* Fonts */
  --avenir: 'Avenir';
}

[data-theme='light'] {
  /* Colors: */
  --pink-indicator: #d40159;
  --green-indicator: #00fe9a;
  --orange-dark: #8b4500;
  --orange-main: #ffba00;
  --turqoise-dark: #09625d;
  --accent-turqoise: #0d9d95;
  --accent-blue: #4192ff;
  --blue-contrast: #001d57;
  --black-main: #19222b;
  --dark-background: #22242a;
  --dark-background-light: #40454f;
  --koyu-mor: #0a1752;
  --gri-lacviert: #14224b;
  --koyu-lacivert: #656d81;
  --lacivert: #001a63;
  --neon-mavi: #00f5ff;
  --tint-1: #797979;
  --tint-2: #9aa3a7;
  --tint-3: #bfc1c3;
  --tint-4: #cdd1d3;
  --success: #12cea3;
  --error: #ff557c;
  --negative: #ff006a;
  --positive: #12cea3;

  --bg-color: #ffffff;
  --bg-secondary-color: rgba(205, 209, 211, 0.25);
  --text-color: #22242a;
  --text-secondary: #cdd1d3;
  --scroll-track: #cdd1d3;
  --scroll-thumb: #22242a;

  /* Sizes: */
  --nav-bar-height: 75px;
  --surface-padding: 23px 24px;

  /* Fonts */
  --avenir: 'Avenir';
}
