.item {
  font-size: 14px;
  padding: 12px 40px !important;
  width: 400px;
  position: relative;
  flex-direction: unset !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  &::before {
    content: '';
    background-image: url('/images/svgs/slider.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    top: 16px;
    left: 22px;
  }
  .title {
    // 848E9c
    margin-bottom: 4px;
    font-weight: 600;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  .description {
    color: #848e9c;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  .time {
    font-size: 12px;
    color: #848e9c;
  }
}
.header {
  justify-content: space-between;
  font-size: 12px;
  display: flex;
  gap: 4px;
  padding: 12px 20px;
  .left {
    display: flex;
    .quantity {
      font-size: 20px;
      margin-right: 10px;
    }
    .clear {
      margin: 0 6px;
    }
  }
  .view {
    margin-left: auto;
    svg {
      margin-left: 6px;
    }
  }
}
.wrapIcon {
  position: relative;
  cursor: pointer;
  .quantity {
    background-color: rgb(246, 70, 93);
    border-radius: 50%;
    font-size: 10px;
    color: #ffffff;
    position: absolute;
    left: 24px;
    top: 0;
    width: 22px;
    height: 22px;
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.wrapMenuNoti {
  padding: 4px 0;
  background: var(--dark-background);
  border-radius: 5px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%);
  background-clip: padding-box;
  margin: 0;
  text-align: left;
  list-style-type: none;
  outline: none;
}
.menuNoti {
  max-height: 340px;
  overflow-y: auto;
  position: relative;
}

.nonNotification {
  padding: 12px 40px !important;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > span {
    margin: 10px 0;
  }
}
.loadMore {
  opacity: 0;
}
