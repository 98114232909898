@import 'overrides.less'; // antd library
@import 'nprogress.less'; // nprogress library
@import 'variables.less';
@import 'utilities.less';
@import 'lib-overrides.less';
@import '404.less';

html,
body {
  width: 100%;
  scroll-behavior: smooth;
}

html {
  text-size-adjust: 100%;
}

body {
  font-size: 14px;
  background: var(--bg-color);
  color: var(--text-color) !important;
}

html,
body,
#root,
#__next,
.app {
  height: 100%;
}

.scroll::-webkit-scrollbar {
  width: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  height: 100%;
}

.scroll::-webkit-scrollbar-track {
  background: var(--scroll-track);
  border-radius: 8px;
  border-radius: 1px solid rgba(255, 255, 255, 0.05);
  height: 100%;
  margin: 0 !important;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: var(--scroll-thumb);
  background-clip: padding-box;
  border: 4.3px solid transparent;
}

[data-theme='light'] .scroll::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
}

.scroll {
  overflow-y: auto;
  flex-grow: 1;
}

.transformButton {
  position: relative;
  margin-top: 24px;
  width: auto;
  span:nth-child(2) {
    display: none;
  }
  &:hover {
    span:nth-child(1) {
      display: none;
    }
    span:nth-child(2) {
      display: block;
    }
  }
  .btn {
    display: flex;
    justify-content: flex-start;
    button {
      border-radius: 50px;
      font-size: 22px;
      color: #8b4500;
      padding: 15px 50px !important;
    }
    align-self: flex-end;
    transition: all 0.5s ease;
  }
}
