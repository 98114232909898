.page_notfound_container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > h1 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    letter-spacing: 2px;
  }
}
