.root {
  width: 466px !important;
}

.root :global .ant-modal-content {
  box-shadow: 0px 0px 10px #00000026;
  border-radius: 10px;
  background: var(--dark-background) 0% 0% no-repeat padding-box;
  background: #22242a 0% 0% no-repeat padding-box;
}
.root :global .ant-modal-header,
.root :global .ant-modal-footer {
  display: none;
}

[data-theme='light'] .root :global .ant-modal-content {
  background-color: var(--bg-color);
}

.confirmBtn {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 136px;
}
.header {
  font-weight: bold;
  margin-bottom: 25px;
  display: flex;
  align-items: flex-start;
}

[data-theme='light'] .header {
  color: #40454f;
}

.settingIcon {
  margin-right: 10px;
  font-size: 19px;
}
.popupTitle {
  border-bottom: 1px solid rgba(191, 193, 195, 0.25);
  padding-bottom: 8px;
  width: 100%;
}

.root :global .ant-switch-handle::before {
  background-image: url('/images/svgs/slider.svg');
  width: 18px;
  height: 18px;
}

.root :global .ant-switch {
  background-color: var(--pink-indicator);
  border: none !important;
  box-shadow: none !important;
}
.root :global .ant-switch-checked {
  background-color: var(--success);
}
.root :global .ant-col-12 div:first-of-type {
  padding-left: 4px;
}

.switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: rgba(64, 69, 79, 0.25);
  border-radius: 10px;
  margin: 10px 0;
}

[data-theme='light'] .switcher {
  background-color: var(--bg-secondary-color);
}

.title {
  font-size: 12px;
  color: var(--tint-3);
  padding-left: 0 !important;
}

[data-theme='light'] .title {
  color: var(--tint-1);
}

.root :global label {
  font-size: 12px !important;
}

.root :global .ant-select-selector {
  padding-right: 27px !important;
}
.root :global .ant-select-selector .ant-select-selection-item {
  font-size: 14px !important;
}

.boxTitle {
  margin: 25px 0 5px;
  border-bottom: 1px solid rgba(191, 193, 195, 0.25);
  padding-bottom: 8px;
  color: var(--tint-3);
}

.root :global .anticon-close {
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  color: black;
  background: var(--tint-3);
}

.selectWrapper :global .ant-select-selection-item {
  text-transform: uppercase;
}

.normal label {
  text-transform: none;
}
