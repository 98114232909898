.root :global .ant-switch {
  background: rgb(154, 163, 167, 0.15) 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
  height: 40px;
  width: 67.83px;
}

[data-theme='light'] .root :global .ant-switch {
  background: #fff !important;
  box-shadow: 0px 0px 10px #00000026 !important;
  border: none;
}

.root :global .ant-switch-checked {
  background-color: transparent;
}

.root :global .ant-switch-handle {
  top: 3px;
  left: 4px;
}
.root :global .ant-switch-handle::before {
  background-image: url('/images/svgs/slider.svg');
  width: 33.04px;
  height: 33.04px;
  border-radius: 20px;
}

[data-theme='light'] .root :global .ant-switch-handle::before {
  background-image: url('/images/svgs/light-slider.svg');
}

.root :global .ant-select-single.ant-select-show-arrow {
  padding-right: 12px !important;
}

.root :global .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 33.04px - 4px);
}
.root :global .ant-switch-inner {
  margin: 8px 0px 0 30px;
  color: var(--orange-main) !important;
}
.root :global .ant-switch-checked .ant-switch-inner {
  margin: 10px 35px 0 7px;
}
