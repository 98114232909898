.root :global .ant-modal-content {
  box-shadow: 0px 0px 10px #00000026;
  border-radius: 10px;
  background: var(--dark-background);
}

// [data-theme='light'] .root :global .ant-modal-content {
//   background: #fff;
// }

.root :global .ant-modal-header,
.root :global .ant-modal-footer {
  display: none;
}

.root :global .ant-modal-body {
  padding: 21px;
}

.root :global .anticon-close {
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  color: black;
  background: var(--tint-3);
}
