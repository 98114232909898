/* Override antd */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
  color: var(--orange-main);
  font-weight: normal;
}

.ant-select-item:hover {
  background-color: transparent !important;
  color: var(--orange-main);
}

.ant-form-item {
  margin-bottom: 24px;
}

.ant-form-item-label {
  display: none;
}

.ant-form-item-children-icon {
  left: 0;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-form-item-explain {
  text-align: end;
  color: #ff557c !important;
  font-size: 12px;
  font-family: Avenir;
  position: absolute;
  height: 16px;
  right: 24px;
  top: 48px;
  font-style: italic;
}

input {
  font-family: Avenir;
}

//overide notification
.ant-notification-notice {
  padding: 15px 10px;
  border-radius: 10px !important;
}
.ant-notification-notice-message {
  font-size: 12px !important;
  margin-bottom: 0 !important;
  margin-left: 30px !important;
}
.ant-notification-notice-with-icon {
  display: flex;
}
.ant-notification-notice-icon {
  font-size: 14px;
  line-height: 16px !important;
  top: 20px;
}
.ant-notification-notice-close {
  top: 5px;
  right: 9px;
}
.ant-notification-close-icon {
  font-size: 12px;
}
.ant-notification-notice-success .ant-notification-notice-message,
.ant-notification-notice-success .ant-notification-notice-icon {
  color: var(--success) !important;
}
.ant-notification-notice-warning .ant-notification-notice-message,
.ant-notification-notice-warning .ant-notification-notice-icon {
  color: var(--orange-main) !important;
}
.ant-notification-notice-error .ant-notification-notice-message,
.ant-notification-notice-error .ant-notification-notice-icon {
  color: var(--error) !important;
}

/* Custom tooltip antd */
.ant-tooltip {
  max-width: 300px;
}

.ant-tooltip-inner {
  color: #ffba00;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 12px;
}

.ant-tooltip-arrow-content {
  width: 20px;
  height: 12px;
}

/* Custom image antd */
.ant-image-mask-info {
  font-size: 14px;
}

.weight-900 {
  font-weight: 900;
}

/*Custom pagination antd*/
.ant-pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.ant-pagination-options {
  visibility: hidden;
}

.ant-pagination-options .ant-select-selector {
  min-height: 32px;
  border: 2px solid #cccccc1a !important;
  background-color: #22242a !important;
  // z-index: 10;
}

.ant-pagination-options .ant-select-selector .ant-select-selection-item {
  line-height: 28px !important;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  min-width: 32px !important;
  height: 32px !important;
  margin-right: 8px !important;
  line-height: 30px !important;
  border: 2px solid #cccccc1a !important;
  position: relative;
  border-radius: 50% !important;
  background-color: var(--dark-background) !important;
  box-sizing: initial !important;
}
.ant-pagination-item > *,
.ant-pagination-prev > *,
.ant-pagination-next > * {
  display: inline-block !important;
  line-height: 1 !important;
  padding: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: var(--text-color);
}
button.ant-pagination-item-link {
  border: none !important;
}
.ant-pagination-item-active {
  background-color: var(--orange-main) !important;
}
.ant-pagination-item-active > * {
  color: var(--orange-dark) !important;
}
.ant-pagination-prev {
  margin-right: 28px !important;
}
.ant-pagination-next {
  margin-left: 20px !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--text-color);
  top: 50% !important;
  transform: translateY(-50%) !important;
}

/*Custom ant-input*/
.ant-input::placeholder {
  padding-right: 4px;
}

/*Custom table antd*/
.ant-table-thead .ant-table-cell {
  color: #9aa3a7;
  font-weight: 400;
}

.ant-table {
  color: var(--text-color);
}

.ant-input {
  color: var(--text-color);
}

.ant-table-cell {
  white-space: nowrap;
}

.ant-table-column-sorters {
  width: fit-content;
}

.ant-table-column-title {
  margin-right: 8px;
}
.ant-table-column-sorter .anticon-caret-up svg {
  width: 10px;
  height: 10px;
}

.ant-table-column-sorter .anticon-caret-down svg {
  width: 10px;
  height: 10px;
}

.ant-spin-nested-loading::-webkit-scrollbar {
  background-color: unset;
  display: block;
  width: 0.1px;
}

/* width */
::-webkit-scrollbar {
  height: 6px;
  width: 8px;
  padding: 20px 20px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--bg-color);
  border-radius: 20px;
  margin: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb);
  border-radius: 20px;
}

/* Input auto complete */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input > input:-webkit-autofill,
input > input:-webkit-autofill:hover,
input > input:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  box-shadow: 0 0 0px 1000px #34434b inset;
  -webkit-box-shadow: 0 0 0px 1000px #34434b inset;
  transition: background-color 10000s ease-in-out 0s;
}

/* modal */
// .ant-modal-mask {
// backdrop-filter: blur(3px);
// -webkit-backdrop-filter: blur(3px);
// background-color: rgba(0, 0, 0, 0.75);
// }
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.85);
}

.ant-menu-submenu-popup > .ant-menu {
  background: var(--dark-background);
  border-radius: 4px;
}

.ant-menu-item-selected,
.ant-menu-item-active {
  background-color: transparent !important;
}

.ant-table-column-sorter-inner {
  background-color: rgba(255, 255, 255, 0.25);
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  span {
    font-size: 6px;
  }
  svg {
    width: 8px !important;
    height: 8px !important;
  }
}

// Message
.ant-message-notice-content {
  background: var(--dark-background);
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px #00000026;
}

// Remove horizontal scroll on table empty
.ant-table-empty {
  div.ant-table-content {
    overflow-x: auto !important;
  }
}
.ant-empty-description {
  color: var(--text-color);
}

// react-grid-layouu
.react-grid-item .react-resizable-handle {
  background: unset;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  opacity: 0;
  position: absolute;
  right: 0px !important;
  bottom: 0px !important;
  width: 8px !important;
  height: 8px !important;
  border-right: 2px solid rgba(255, 255, 255, 0.5) !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5) !important;
  transition: 0.2s;
}

[data-theme='light'] .react-grid-item > .react-resizable-handle::after {
  border-right: 2px solid rgba(0, 0, 0, 0.5) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5) !important;
}

[data-theme='light'] .ant-radio-wrapper {
  color: var(--text-color);
}
[data-theme='light'] .ant-tabs {
  color: var(--text-color);
}

.react-grid-item:hover > .react-resizable-handle::after {
  opacity: 1;
}

@media (max-width: 992px) {
  .ant-modal-body {
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .ant-pagination {
    margin-left: 0 !important;
  }
}

[data-theme='light'] .ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  filter: brightness(0.75) !important;
  -webkit-filter: brightness(0.75) !important;
}

[data-theme='light'] .ant-notification-notice {
  background-color: var(--bg-color);
  box-shadow: 0px 0px 10px #00000026;
}

[data-theme='light'] .ant-notification-notice-close {
  color: var(--tint-2);
}
