.root {
  position: fixed;
  padding: 0 !important;
  top: 0px;
  left: 0px;
  width: 100%;
  background: rgba(34, 36, 42, 0.25) !important;
  height: var(--nav-bar-height) !important;
  backdrop-filter: blur(12px);
  z-index: 10;
}

[data-theme='light'] .root {
  background: #fff !important;
}

.content {
  padding: 0px 16;
  line-height: normal;
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  margin-right: 38px;
}

.userEmail {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > img {
    margin-right: 12px;
    padding-bottom: 4px;
  }
}

.menu {
  border-bottom: none !important;
  background: none !important;
  flex: 1 1 0;
}

.menu :global .ant-menu-item-selected a,
.menu :global .ant-menu-submenu-selected a {
  color: var(--orange-main) !important;
}

.menu :global .ant-menu-item a {
  color: var(--text-color);
  font-weight: 400;
}

.menu :global .ant-menu-item-active a {
  color: var(--orange-main) !important;
}

.menu li {
  padding: 8px 16px !important;
}

.menu li::after {
  border: none !important;
}

.rightMenu {
  height: 100%;
}

.rightMenu :global .ant-space,
.rightMenu :global .ant-space-item,
.rightMenu :global .ant-dropdown-trigger {
  height: 100%;
  display: flex;
  align-items: center;
}

.accountButton > * {
  width: 40px;
  height: 40px;
  padding: 0px;
  border-width: 2px;
}

[data-theme='light'] .accountButton :global .ant-avatar {
  border: none;
  box-shadow: 0px 0px 10px #00000026;
}

.downOutline {
  color: var(--orange-main) !important;
  margin-left: 8px;
}

.accountDropdown li {
  min-width: 300px;
  text-align: right;
}

.settingButton :global button {
  width: 40px;
  height: 40px;
  padding: 0px;
  border-width: 2px;
  position: relative;
}

[data-theme='light'] .settingButton :global button {
  border: none;
  box-shadow: 0px 0px 10px #00000026;
}

.settingButton :global .anticon {
  font-size: 19px;
  position: absolute !important;
  top: 8px;
  left: 1px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: var(--text-color);
}

[data-theme='light'] .settingButton :global .anticon {
  top: 10px;
  color: #9aa3a7 !important;
}

.walletMenu {
  color: #fff;
  &:hover {
    color: var(--orange-main);
  }
}

.nickNameText {
  font-weight: 300;
}

.earningDropdown {
  padding: 0 !important;
}

@media (max-width: 1370px) {
  .logo {
    margin: 0 20px;
  }
}

@media (max-width: 1200px) {
  .rightMenu {
    .accountButton,
    .langButton {
      padding: 12px 4px;
    }
  }
  .buttons {
    gap: 4px !important;
  }
  .menu li {
    padding: 8px 10px !important;
  }
}
