.header {
  margin-bottom: 42px;
  b {
    margin-bottom: 16px;
  }
}

.card {
  width: 212px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  margin-bottom: 74px;
}

.btnSubmit {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.outerModal {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    border: none;
    display: flex;
    align-items: center;
    svg {
      margin-left: 8px;
    }
  }
}
