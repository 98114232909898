.downOutline {
  color: var(--orange-main) !important;
  margin-left: 8px;
}

@media (max-width: 1200px) {
  .langButton {
    padding: 12px 4px;
  }
}
